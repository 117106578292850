.dialog-window {
	display: grid;
	grid-template-columns: min-content 1fr 1fr;
	gap: 2rem 1rem;
	align-items: start;

	.disabled {
		color: #ccc;
	}
}
