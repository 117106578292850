.page-grid {
	display: grid;
	grid-template-columns: min-content minmax(700px, auto) min-content;
	gap: 16px;
	margin: 0 auto 32px auto;

	.team-grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 16px;
	}
}

.drop-preview {
	background-color: rgba(0, 0, 0, 0.1);
}

.card-group {
	border: 1px solid #ccc;
	overflow-y: auto;
}

.draggable-card {
	padding: 10px;
	border-bottom: 1px solid #ccc;
	display: grid;
	grid-template-columns: auto min-content;
	border: 1px solid rgba(0, 0, 0, 0.33);
}

.tool-tip-time {
	text-align: center;
	color: grey;
}

.text-field {
	border: solid black 1px;
}

.button-group {
	Button {
		margin: 0 16px;
	}
}

.dragHandleOp {
	cursor: move;
}

$op-color: #7f7f7f;

.scheduled,
.non-scheduled {
	.locked-pattern {
		background: repeating-linear-gradient(
			45deg,
			transparent,
			transparent 15px,
			rgba($op-color, 0.25) 15px,
			rgba($op-color, 0.25) 30px
		);
	}

	.op {
		background-color: #aaa;
		border-left: 1px solid black;
		border-right: 1px solid black;
		cursor: move;
	}
}

.scheduled {
	.preOp,
	.op,
	.postOp {
		transition-property: background-color;
		transition-duration: 3s;
	}
}

.opacity-ghost {
	transition: all 0.5s ease;
	opacity: 0.8;
	cursor: move;
	transform: rotateZ(5deg);
	box-shadow: 3px 10px 10px 3px rgba(0, 0, 0, 0.3);
}
